import styled from 'styled-components';
// import LoginForm from '@/components/login/index';
import { authenticate } from '@/middleware/auth';
import { setAuthCookie } from '@/util/set-jwt';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoginPage = () => {
  return <Container>{/* <LoginForm /> */}</Container>;
};

export async function getServerSideProps({ req, res, query }) {
  try {
    const user = await authenticate(req, res);

    if (user) {
      if (query.token) {
        setAuthCookie(res, query.token);
      }

      return {
        redirect: {
          destination: '/',
          permanent: false,
        },
      };
    }
  } catch {}

  return { props: {} };
}

export default LoginPage;
